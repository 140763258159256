<template>
    <v-select
        v-model="claim"
        :items="claims"
        :loading="isLoading"
        :readonly="readonly"
        no-filter
        hide-no-data
        item-text="id"
        item-value="id"
        :label="label"
        placeholder=""
        return-object
    >
        <template v-slot:item="item">
            <v-list-item-content>
                <v-list-item-title>Ticket #{{ item.item.id }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.item.title }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
<!--                <v-icon>mdi-coin</v-icon>-->
            </v-list-item-action>
        </template>
    </v-select>
</template>

<script>
    export default {
        components: {},

        props: {
            label: String,
            value: Object,
            readonly: Boolean,
            customer: Object
        },

        data() {
            return {
                claim: null,

                isLoading: false,
                claimSearch: ''
            };
        },

        computed: {
            claims() {
                return this.$store.getters['claims/getAll']();
            }
        },

        watch: {
            claim(v) {
                this.$emit('input', v);
            },

            customer: {
                immediate: true,
                async handler(v) {
                    this.isLoading = true;

                    try {
                        await this.$store.dispatch('claims/fetch', {
                            customer: this.customer.id
                        });
                    }
                    finally {
                        this.isLoading = false;
                    }
                }
            },

            value(v) {
                this.claim = v;
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
