<template>
    <v-select
        label="Dirección de entrega"
        v-model="address"
        :items="addresses"
        item-text="address"
        item-value="id"
        :disabled="!customer"
        return-object
        required
    >
        <template v-if="showAdd" v-slot:append-outer>
            <v-btn icon @click="onAddSelected">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
    </v-select>
</template>

<script>
    export default {
        components: {},

        props: {
            customer: {
                type: Object
            },
            value: {
                type: Object
            },
            showAdd: {
                type: Boolean
            }
        },

        data() {
            return {
                isLoading: false,
                address: null
            };
        },

        computed: {
            addresses() {
                if(!this.customer) {
                    return [];
                }

                return this.customer.shippingAddresses;
            }
        },

        watch: {
            value(v) {
                this.address = v;
            },

            address(value) {
                this.$emit('input', value);
            },

            customer: {
                immediate: true,
                handler(value, oldValue) {
                    if(!value || !value.id || (oldValue && value.id === oldValue.id)) {
                        return;
                    }

                    this.isLoading = true;
                    this.$store.dispatch('customer/fetchShippingAddresses', { customer: this.customer });
                }
            }
        },

        methods: {
            onAddSelected() {
                this.$emit('add-selected');
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
