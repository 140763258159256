<template>
    <template-layout>
        <template slot="content">
            <customer-shipping-address-create-drawer
                :active.sync="showShippingAddressCreateDrawer"
                :customer="customer"
            />

            <customer-billing-address-create-drawer
                :active.sync="showBillingAddressCreateDrawer"
                :customer="customer"
            />

            <customer-billing-address-edit-drawer
                :active.sync="showBillingAddressEditDrawer"
                :customer="customer"
                :billingAddress="this.billingAddress"
            />

            <v-container v-if="cart">
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Crear Pedido</span>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-text>
                                <customer-select v-model="customerSearch"></customer-select>

                                <v-select
                                    label="Tipo de Pedido"
                                    v-model="orderType"
                                    :items="orderTypes"
                                    item-text="name"
                                    item-value="value"
                                    :disabled="!customer"
                                    required
                                ></v-select>

                                <v-row v-if="orderType === 'support'">
                                    <v-col cols="12" md="5">
                                        <claim-select
                                            label="Ticket"
                                            :customer="customer"
                                            v-model="orderClaim"
                                        ></claim-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card v-if="customer" class="pa-0">
                            <v-card-title>Ficha del Cliente</v-card-title>

                            <v-card-text>
                                <v-list-item>
                                    <v-list-item-content>Nombre</v-list-item-content>
                                    <v-list-item-content class="align-end">{{ customer.fullName }}</v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>Correo electrónico</v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <a :href="'mailto:' + customer.email" target="_blank">{{ customer.email }}</a>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>Tipo de cliente</v-list-item-content>
                                    <v-list-item-content class="align-end">{{ customer.isWholesaler ? 'Mayorista' : 'Minorista' }}</v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>Deuda</v-list-item-content>
                                    <v-list-item-content class="align-end">{{ customer.debt | toCurrency }}</v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <span class="title">Entrega</span>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-title>
                                Entrega
                            </v-card-title>

                            <v-card-text>
                                <v-select
                                    label="Características"
                                    v-model="deliveryFeatures"
                                    :items="vehicleFeatures"
                                    :disabled="!customer"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    multiple
                                ></v-select>

                                <customer-shipping-address-select
                                    :customer="customer"
                                    v-model="shippingAddress"
                                    show-add
                                    @add-selected="onAddShippingAddressSelected"
                                />

                                <div v-if="shippingAddress" class="mb-4">
                                    <span class="d-block">{{ shippingAddress.area }}</span>
                                    <span class="d-block">{{ shippingAddress.place }}</span>
                                    <span class="d-block">Unidad: {{ shippingAddress.unit }}</span>
                                    <span class="d-block">Contacto: {{ shippingAddress.phone }} / {{ shippingAddress.cellphone }}</span>
                                </div>

                                <order-delivery-schedule-select
                                    :zone="deliveryZone"
                                    :address="shippingAddress"
                                    :features="deliveryFeatures"
                                    v-model="deliverySchedule"
                                />
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="6" v-show="shippingAddress">
                        <v-card>
                            <v-card-title>
                                <span>Ubicación</span>
                            </v-card-title>

                            <v-card-text>
                                <tui-google-map
                                    name="map_1"
                                    ref="map"
                                    style="height: 300px;"
                                ></tui-google-map>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>
                                <span class="title">Pedido</span>
                            </v-card-title>

                            <v-card-text>
                                <order-editable-cart
                                    :cart="cart"
                                    :customer="customer"
                                ></order-editable-cart>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-title>Total</v-card-title>

                            <v-card-text class="body-1">
                                <v-row v-for="(error,key) in cart.errors" v-bind:key="key">
                                    <v-col cols="12">
                                        <span class="red--text">{{ $t(error) }}</span>
                                    </v-col>
                                </v-row>

                                <v-row v-for="(error,key) in errors" v-bind:key="key">
                                    <v-col cols="12">
                                        <span class="red--text">{{ $t(error) }}</span>
                                    </v-col>
                                </v-row>

                                <v-row v-if="cart">
                                    <v-list-item>
                                        <v-list-item-content>Subtotal</v-list-item-content>
                                        <v-list-item-content class="align-end">{{ cart.subtotal | toCurrency }}</v-list-item-content>
                                    </v-list-item>

                                    <v-list-item v-for="(discount, key) in cart.discounts" v-bind:key="key">
                                        <v-list-item-content>{{ discount.name }}</v-list-item-content>
                                        <v-list-item-content class="align-end">{{ -discount.amount | toCurrency }}</v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>Total</v-list-item-content>
                                        <v-list-item-content class="align-end">{{ cart.total | toCurrency }}</v-list-item-content>
                                    </v-list-item>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-title>Facturación</v-card-title>

                            <v-card-text>
                                <customer-billing-address-select
                                    v-model="billingAddress"
                                    :customer="customer"
                                    show-add
                                    @add-selected="onAddBillingAddressSelected"
                                    @edit-selected="onEditBillingAddressSelected"
                                />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="text-right">
                        <v-btn
                            color="primary"
                            :disabled="!isValid"
                            @click="onSave">
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    /* global google */
    import TuiGoogleMap from '@/ui/TUIGoogleMap';
    import TemplateLayout from '@/layout/TemplateLayout.vue';

    import CustomerSelect from '@/components/Customer/CustomerSelect';
    import CustomerShippingAddressSelect from '@/components/Customer/CustomerShippingAddressSelect';
    import CustomerBillingAddressSelect from '@/components/Customer/CustomerBillingAddressSelect';
    import OrderDeliveryScheduleSelect from '@/components/OrderDeliveryScheduleSelect';
    import OrderEditableCart from '@/components/Order/OrderEditableCart';
    import CustomerShippingAddressCreateDrawer from '@/components/Customer/CustomerShippingAddressCreateDrawer';
    import CustomerBillingAddressCreateDrawer from '@/components/Customer/CustomerBillingAddressCreateDrawer';
    import CustomerBillingAddressEditDrawer from '@/components/Customer/CustomerBillingAddressEditDrawer';
    import ClaimSelect from '../../../components/Claim/ClaimSelect.vue';

    export default {
        components: {
            TemplateLayout,
            TuiGoogleMap,
            OrderEditableCart,
            CustomerShippingAddressSelect,
            CustomerBillingAddressSelect,
            CustomerSelect,
            OrderDeliveryScheduleSelect,
            CustomerShippingAddressCreateDrawer,
            CustomerBillingAddressCreateDrawer,
            CustomerBillingAddressEditDrawer,
            ClaimSelect
        },

        data() {
            return {
                showShippingAddressCreateDrawer: false,
                showBillingAddressCreateDrawer: false,
                showBillingAddressEditDrawer: false,

                orderTypes: [
                    { name: 'Normal', value: 'normal' },
                    { name: 'Reposición', value: 'support' }
                ],

                orderType: null,
                orderClaim: null,
                customerSearch: null,
                customer: null,
                cartId: null,
                shippingAddress: null,
                deliveryZone: null,
                deliverySchedule: null,
                billingAddress: null,
                deliveryFeatures: [],
                errors: []
            };
        },

        computed: {
            cart() {
                return this.$store.getters['cart/get']();
            },

            isValid() {
                if(!this.customer || !this.cart) {
                    return false;
                }

                if(!this.shippingAddress) {
                    return false;
                }

                if(!this.deliverySchedule) {
                    return false;
                }

                if(this.cart.errors.length > 0 && this.cart.errors.includes('error.order.billing_required')) {
                    return false;
                }

                if(!this.cart.items || this.cart.items.length === 0) {
                    return false;
                }

                if(this.orderType === 'support' && !this.orderClaim) {
                    return false;
                }

                return true;
            },

            vehicleFeatures() {
                return this.$store.getters['vehicleFeatures/getAll']();
            }
        },

        watch: {
            async customerSearch(value, prev) {
                if(prev && value.id === prev.id) {
                    return;
                }

                await this.$store.dispatch('customer/fetchOne', value.id);

                this.customer = this.$store.getters['customer/getById'](value.id);
            },

            orderType(value) {
                this.$store.dispatch('cart/setOrderType', {
                    cart: this.cart,
                    orderType: this.orderType
                });
            },

            customer(value) {
                if(value) {
                    // set customer in cart
                    this.$store.dispatch('cart/setCustomer', {
                        cart: this.cart,
                        customer: value
                    });
                }
            },

            shippingAddress(value) {
                this.deliveryZone = null;

                let loc = new google.maps.LatLng(value.location.lat, value.location.lng);

                this.$refs.map.clearMarkers();
                this.$refs.map.setCenter(loc);
                this.$refs.map.setMarker(loc);
                this.$refs.map.setZoom(16);

                this.$api.getZonesByLocation(value.location).then((zone) => {
                    this.deliveryZone = zone;
                });

                this.$store.dispatch('cart/setShippingAddress', {
                    cart: this.cart,
                    address: value
                });
            },

            billingAddress: {
                deep: true,
                handler(value) {
                    this.$store.dispatch('cart/setBillingAddress', {
                        cart: this.cart,
                        address: value
                    });
                }
            },

            deliverySchedule: {
                deep: true,
                handler(value) {
                    this.$store.dispatch('cart/setDeliverySchedule', {
                        cart: this.cart,
                        schedule: value
                    });
                }
            }
        },

        mounted() {
            this.$store.dispatch('cart/create');
            this.$store.dispatch('vehicleFeatures/fetchAll');

            this.orderType = this.orderTypes[0].value;
        },

        methods: {
            onAddShippingAddressSelected() {
                this.showShippingAddressCreateDrawer = true;
            },

            onAddBillingAddressSelected() {
                this.showBillingAddressCreateDrawer = true;
            },

            onEditBillingAddressSelected() {
                this.showBillingAddressEditDrawer = true;
            },

            async onSave() {
                try {
                    let extra = {
                        ...(this.orderClaim ? { claim: this.orderClaim.id } : {})
                    };

                    let items = this.cart.items.map(cartItem => {
                        return {
                            product: cartItem.product.id,
                            quantity: cartItem.quantity
                        };
                    });

                    let result = await this.$store.dispatch('order/create', {
                        type: this.orderType,
                        customer_id: this.customer.id,
                        billing_address_id: this.billingAddress.id,
                        shipping_address_id: this.shippingAddress.id,
                        shipping_date: this.deliverySchedule.date,
                        shipping_start_time: this.deliverySchedule.start,
                        shipping_end_time: this.deliverySchedule.end,
                        vehicleFeatures: this.deliveryFeatures.map(item => item.id),
                        items: items,
                        extra: extra
                    });

                    this.$router.replace({ name: 'orderView', params: { id: result } });
                }
                catch (err) {
                    this.errors = [];
                    this.errors.push(err.code);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-subheader {
        padding: 0 !important;
    }
</style>
