<template>
    <div>
        <div>
            <v-select
                label="Horario"
                v-model="selectedSchedule"
                :items="schedules"
                item-text="displayName"
                item-value="id"
                :loading="isLoading"
                :disabled="!zone || isLoading"
                return-object
                required>

                <template v-slot:item="item">
                    <template v-if="item.item.id > 0">
                        {{ item.item.date | moment('dddd D MMMM') }} de {{ item.item.start }} a {{ item.item.end }}

                        <span v-if="item.item.full" class="red--text"> (Completo)</span>
                        <span v-if="item.item.holiday" class="red--text"> (Feriado)</span>
                    </template>
                    <template v-else>
                        Otro
                    </template>
                </template>

                <template v-slot:selection="item">
                    <template v-if="item.item.id > 0">
                        {{ item.item.date | moment('dddd D MMMM') }} de {{ item.item.start }} a {{ item.item.end }}

                        <span v-if="item.item.full" class="red--text"> (Completo)</span>
                        <span v-if="item.item.holiday" class="red--text"> (Feriado)</span>
                    </template>
                    <template v-else>
                        Otro
                    </template>
                </template>
            </v-select>
        </div>

        <div v-if="selectedSchedule && selectedSchedule.id === -1">
            <v-row>
                <v-col cols="3">
                    <validation-provider name="day" v-slot="{ errors }" rules="required">
                        <v-menu
                            v-model="dateMenuOpen"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="Día"
                                    color="primary"
                                    v-model="selectedSchedule.date"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-on="on"
                                    :error-messages="errors"
                                ></v-text-field>
                            </template>

                            <v-date-picker
                                v-model="selectedSchedule.date"
                                @input="dateMenuOpen = false"
                                no-title
                                :first-day-of-week="1"
                                locale="es-AR"
                                event-color="green lighten-1"
                                :allowed-dates="allowedDates"
                            >
                            </v-date-picker>
                        </v-menu>
                    </validation-provider>
                </v-col>

                <v-col cols="3">
                    <validation-provider name="timeStart" v-slot="{ errors }" rules="required">
                        <v-text-field
                            v-model="selectedSchedule.start"
                            v-mask="maskTime"
                            label="Desde"
                            append-icon="mdi-clock-outline"
                            :error-messages="errors"
                        />
                    </validation-provider>
                </v-col>

                <v-col cols="3">
                    <validation-provider name="timeEnd" v-slot="{ errors }" rules="required">
                        <v-text-field
                            v-model="selectedSchedule.end"
                            v-mask="maskTime"
                            label="Hasta"
                            append-icon="mdi-clock-outline"
                            :error-messages="errors"
                        />
                    </validation-provider>
                </v-col>

                <v-col cols="3">
                    <v-btn
                        block
                        dark
                        :loading="isLoading"
                        :color="verifyButtonColor"
                        @click="onVerify">
                        Verificar
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationProvider
        },

        props: {
            zone: Object,
            features: Array,
            address: Object
        },

        data() {
            return {
                dateMenuOpen: false,
                maskTime: '##:##',
                isLoading: false,
                selectedSchedule: {},
                schedulesValues: [],
                isVerified: false,
                isScheduleValid: false
            };
        },

        computed: {
            canVerify() {
                return this.selectedSchedule.start && this.selectedSchedule.end && this.selectedSchedule.date;
            },

            verifyButtonColor() {
                if(this.isVerified) {
                    return this.isScheduleValid ? 'green' : 'red';
                }
                else {
                    return 'gray';
                }
            },

            schedules() {
                return [
                    ...this.schedulesValues,
                    { id: -1, full: true }
                ];
            }
        },

        watch: {
            selectedSchedule: {
                deep: true,
                handler(v) {
                    if(v && v.id !== -1) {
                        this.$emit('input', { ...v });
                    }
                    else {
                        this.isVerified = false;
                        this.$emit('input', null);
                    }
                }
            },

            features: {
                deep: true,
                async handler(v) {
                    this.selectedSchedule = null;

                    this.isLoading = true;

                    try {
                        await this.checkSchedulesAvailability();
                    }
                    finally {
                        this.isLoading = false;
                    }
                }
            },

            async zone(value) {
                if(!value) {
                    return;
                }

                this.isLoading = true;
                this.schedulesValues = [];

                try {
                    this.schedulesValues = await this.$api.get('/api/zones/' + this.zone.id + '/availableSchedules');

                    await this.checkSchedulesAvailability();
                }
                finally {
                    this.isLoading = false;
                }
            },

            value(value) {
                if(!value) {
                    return;
                }

                this.$emit('value', value);
            }
        },

        methods: {
            allowedDates(val) {
                return this.$moment(val).diff(this.$moment()) > 1;
            },

            async checkSchedulesAvailability() {
                for(let schedule of this.schedulesValues) {
                    if(schedule.full) {
                        continue;
                    }

                    let result = await this.verifySchedule({
                        address: this.address,
                        date: schedule.date,
                        start: schedule.start,
                        end: schedule.end,
                        features: this.features
                    });

                    schedule.full = !result;
                }
            },

            async onVerify() {
                this.isLoading = true;

                try {
                    let result = await this.verifySchedule({
                        address: this.address,
                        date: this.selectedSchedule.date,
                        start: this.selectedSchedule.start,
                        end: this.selectedSchedule.end,
                        features: this.features
                    });

                    this.isScheduleValid = result;
                    this.selectedSchedule.full = !result;

                    this.$emit('input', result ? { ...this.selectedSchedule } : null);
                }
                catch {
                    this.isScheduleValid = false;
                }
                finally {
                    this.isVerified = true;
                    this.isLoading = false;
                }
            },

            async verifySchedule({ address, date, start, end, features }) {
                try {
                    let result = await this.$api.get('/api/shipment_routes/availability', {
                        address: address.id,
                        date: date,
                        start: start,
                        end: end,
                        features: features.map(f => f.id)
                    });

                    return result.available;
                }
                catch (err) {
                    return false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
