var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-select',{attrs:{"label":"Horario","items":_vm.schedules,"item-text":"displayName","item-value":"id","loading":_vm.isLoading,"disabled":!_vm.zone || _vm.isLoading,"return-object":"","required":""},scopedSlots:_vm._u([{key:"item",fn:function(item){return [(item.item.id > 0)?[_vm._v(" "+_vm._s(_vm._f("moment")(item.item.date,'dddd D MMMM'))+" de "+_vm._s(item.item.start)+" a "+_vm._s(item.item.end)+" "),(item.item.full)?_c('span',{staticClass:"red--text"},[_vm._v(" (Completo)")]):_vm._e(),(item.item.holiday)?_c('span',{staticClass:"red--text"},[_vm._v(" (Feriado)")]):_vm._e()]:[_vm._v(" Otro ")]]}},{key:"selection",fn:function(item){return [(item.item.id > 0)?[_vm._v(" "+_vm._s(_vm._f("moment")(item.item.date,'dddd D MMMM'))+" de "+_vm._s(item.item.start)+" a "+_vm._s(item.item.end)+" "),(item.item.full)?_c('span',{staticClass:"red--text"},[_vm._v(" (Completo)")]):_vm._e(),(item.item.holiday)?_c('span',{staticClass:"red--text"},[_vm._v(" (Feriado)")]):_vm._e()]:[_vm._v(" Otro ")]]}}]),model:{value:(_vm.selectedSchedule),callback:function ($$v) {_vm.selectedSchedule=$$v},expression:"selectedSchedule"}})],1),(_vm.selectedSchedule && _vm.selectedSchedule.id === -1)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Día","color":"primary","prepend-inner-icon":"mdi-calendar","readonly":"","error-messages":errors},model:{value:(_vm.selectedSchedule.date),callback:function ($$v) {_vm.$set(_vm.selectedSchedule, "date", $$v)},expression:"selectedSchedule.date"}},on))]}}],null,true),model:{value:(_vm.dateMenuOpen),callback:function ($$v) {_vm.dateMenuOpen=$$v},expression:"dateMenuOpen"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1,"locale":"es-AR","event-color":"green lighten-1","allowed-dates":_vm.allowedDates},on:{"input":function($event){_vm.dateMenuOpen = false}},model:{value:(_vm.selectedSchedule.date),callback:function ($$v) {_vm.$set(_vm.selectedSchedule, "date", $$v)},expression:"selectedSchedule.date"}})],1)]}}],null,false,4053599686)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"timeStart","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskTime),expression:"maskTime"}],attrs:{"label":"Desde","append-icon":"mdi-clock-outline","error-messages":errors},model:{value:(_vm.selectedSchedule.start),callback:function ($$v) {_vm.$set(_vm.selectedSchedule, "start", $$v)},expression:"selectedSchedule.start"}})]}}],null,false,460247383)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"timeEnd","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskTime),expression:"maskTime"}],attrs:{"label":"Hasta","append-icon":"mdi-clock-outline","error-messages":errors},model:{value:(_vm.selectedSchedule.end),callback:function ($$v) {_vm.$set(_vm.selectedSchedule, "end", $$v)},expression:"selectedSchedule.end"}})]}}],null,false,1930203556)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"block":"","dark":"","loading":_vm.isLoading,"color":_vm.verifyButtonColor},on:{"click":_vm.onVerify}},[_vm._v(" Verificar ")])],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }