<template>
    <v-navigation-drawer
        v-model="drawerActive"
        right
        temporary
        fixed
        width="500"
    >
        <v-container class="px-6 fill-height" v-if="billingAddress">
            <v-row class="fill-height">
                <v-col>
                    <v-row class="align-center pb-6">
                        <v-btn icon class="black--text" @click="onCancelSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Editar datos de facturación</span>
                    </v-row>

                    <validation-observer ref="form" v-slot="{ valid }">
                        <v-form>
                            <validation-provider name="cuit" v-slot="{ errors }" rules="required" v-if="billingAddress.ivaCondition === 'Consumidor Final'">
                                <v-text-field
                                    v-model="data.cuit"
                                    v-mask="maskDni"
                                    :label="$t('fields.customerDNI')"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider name="cuit" v-slot="{ errors }" rules="required" v-else>
                                <v-text-field
                                    v-model="data.cuit"
                                    v-mask="maskCuit"
                                    :label="$t('fields.customerCUIT')"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider name="businessName" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.name"
                                    :label="$t('fields.businessName')"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider name="fiscalAddress" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.address"
                                    :label="$t('fields.fiscalAddress')"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="primary" block dark @click="onConfirmSelected" :loading="isSaving">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            customer: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            },

            billingAddress: {
                type: Object
            }
        },

        data() {
            return {
                isSaving: false,
                data: {
                    name: '',
                    address: '',
                    cuit: ''
                },

                maskDni: '########',
                maskCuit: '##-########-#'
            };
        },

        computed: {
            drawerActive: {
                get() {
                    return this.active;
                },

                set(val) {
                    this.$emit('update:active', val);
                }
            }
        },

        watch: {
            billingAddress(val) {
                this.data.name = val.name;
                this.data.address = val.address;
                this.data.cuit = val.cuit;
            }
        },

        methods: {
            async onConfirmSelected() {
                this.isSaving = true;

                let payload = {
                    customer: this.customer,
                    address: this.billingAddress,
                    data: this.data
                };
                payload.data.cuit = payload.data.cuit.replace(/-/g, '');

                try {
                    await this.$store.dispatch('customer/updateBillingAddress', payload);
                    this.$emit('update:active', false);
                }
                catch (err) {
                    console.log(err);
                    if(err.code === 'error.request.invalid') {
                        let formErrors = {};

                        for(let errField in err.errors) {
                            formErrors[errField] = this.$t(err.errors[errField]);
                        }

                        this.$refs.form.setErrors(formErrors);
                    }
                }
                finally {
                    this.isSaving = false;
                }
            },

            onCancelSelected(value) {
                this.$emit('update:active', false);
            }
        }
    };
</script>
